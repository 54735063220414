import * as React from "react"
import styled from "styled-components"

const Box = styled.div`
  height: 1.85rem!important;
  border: solid 1px var(--c-mint-100);
  padding: 0 1.125rem;
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0.85rem;
  right: 5.35rem;
  border-radius: 10rem;
  background-color: var(--c-blue-100);

  a {
    text-transform: uppercase;
    font-size: 0.75rem;
    color: var(--c-mint-100);
    font-weight: bold;
      text-decoration: none!important;
  }

  @media (max-width: 766px) {
    display: flex;
  }

  @media (prefers-color-scheme: light) {
    background-color: #13171D;
    a {
      color: var(--c-mint-100)!important;
    }
      .text-mint {
          color: var(--c-mint-100)!important;
      }
  }
`

const Ele = () => {
  return (
    <Box>
      <a
        href="https://invest.myxeno.com/login"
        target="_blank"
        rel="noreferrer"
        className="text-mint"
      >
        Login
      </a>
    </Box>
  )
}
export default Ele
