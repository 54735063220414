import React, { useEffect } from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import Meta from "../utilities/seo";
import TulumbeHeader from "../components/layout/tulumbe-header";
import HowItWorksContainer from "../microsites/tulumbe/how-it-works-container";
import VisitMorocco from "../microsites/tulumbe/visit-morocco";
import ClientMoroccoExperience from "../microsites/tulumbe/client-morocco-experience";
import Footer from "../components/layout/footer";
import ClientMoroccoExperiencePackage1 from "../microsites/tulumbe/client-morocco-experience-package-1";
import ClientMoroccoExperiencePackage2 from "../microsites/tulumbe/client-morocco-experience-package-2";
import ClientMoroccoExperiencePackage3 from "../microsites/tulumbe/client-morocco-experience-package-3";
import FrenquentlyAnsweredQuestions from "../microsites/tulumbe/frenquently-answered-questions";
import StillHaveQuestions from "../microsites/tulumbe/still-have-questions";
import HeroPage from "../microsites/tulumbe/blocks/hero-page";



const ScrollContainer = styled.div `
    overflow-y: auto;
    overflow-x: hidden;
`;


const Tulumbe = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "#010f27";
    document.body.style.color = "white";
    return () => {
      document.body.style.backgroundColor = "";
      document.body.style.color = "";
    };
  }, []);

  return (
    <ScrollContainer>
      <Meta
        image={{
          url: "https://res.cloudinary.com/dtechse6f/image/upload/v1743500184/web-banner_adwwx6.jpg",
          height: "718",
          width: "1024",
        }}
        title="Tulumbe Morocco: One click trip planning & Auto-Saving for your AFCON 2025 to cheer the Uganda Cranes in Morocco"
        description="Join XENO today to plan and save for AFCON 2025. Enjoy one-click planning and automated savings, ensuring you support the Uganda Cranes in Morocco with ease."
        keywords={[
          "XENO AFCON 2025 savings",
          "One-click trip planning for AFCON 2025 in Morocco",
          "Automated savings for AFCON 2025 trip",
          "Uganda Cranes AFCON 2025 supporters",
          "XENO goal-based investment service",
          "AFCON 2025 travel packages Uganda",
          "Save for AFCON 2025 experience",
          "XENO AutoSave on MTN MoMo",
          "Financial planning for Uganda Cranes fans for AFCON",
          "Secure your spot at AFCON 2025 with XENO"
        ]}
      />
      <TulumbeHeader />


          <HeroPage/>

        <HowItWorksContainer/>

        <VisitMorocco/>

        <ClientMoroccoExperience/>
      <ClientMoroccoExperiencePackage1/>

        <ClientMoroccoExperiencePackage2/>

        <ClientMoroccoExperiencePackage3/>

        <FrenquentlyAnsweredQuestions/>

        <StillHaveQuestions/>


      <Footer bgColor="#0d1016" textColor="white"/>
    </ScrollContainer>
  )
}

export default Tulumbe
