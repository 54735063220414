import React from "react";
import styled from "styled-components";
import PackageOne from "../../images/tulumbe/package-1.png";
import PackageOneMobile from "../../images/tulumbe/package-mobile-1.png";
import PackageNumberOne from "../../images/tulumbe/package-01.png";

import "bootstrap/dist/css/bootstrap.min.css";
import GradientBackground from "./gradient-background"
import GradientButton from "./blocks/gradient-button"



const Content = styled.div`
    margin-top: -57rem;
    margin-bottom: 6rem;
    .x-font-size {
        font-size: 22px;
    }
`;

const MoroccoExperienceSection = styled.div`
   display: flex; 
  flex-direction: column;
    align-items: center;
  text-align: center;
  color: white;
    position: relative;
    z-index: 2;
`;


const PackageTitle = styled.h2`
  font-size: 75px;
  font-weight: bold;
  margin-bottom: 1rem;
    background: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: white;
    background-clip: text;
    color: white;
    width: 99.6%;
`;

const PackageImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    
    img {
        width: 100%;
        max-width: 100%;
    }

    margin-top: -124px;
    margin-right: -155px;
    margin-bottom: -97px;

`;

const ContentWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; 
    width: 100%;

`;

const PackageTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    height: 100%; 
    text-align: left;
    .x-bottom-padding {
        padding-bottom: 8rem !important;
    }
`;

const ClientMoroccoExperiencePackage1 = () => {


  return (

        <div className="container-fluid text-white px-lg-5">
          <Content className="xmrc-topnegativespace">
            <MoroccoExperienceSection>
              <ContentWrapper className="px-lg-5">
              <GradientBackground
                className="gradient-background-0"
                style={{ height: "auto", width: "100%", }}
                radius="30px">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-6">
                        <PackageTextWrapper className="mrc-padding">
                          <div className="mb-lg-5 pb-lg-5 mx-lg-5">
                            <img src={PackageNumberOne} className="pb-lg-4 mb-lg-4 mrc-desktop" alt="package one"/>
                            <div className="xrd-reduce-margin-0">
                              <img src={PackageOneMobile} className="pb-lg-4 mb-lg-4 mrc-mobile" alt="package one"/>
                            </div>

                          <PackageTitle className="pb-lg-3 mb-lg-3 mrc-desktop">Budget</PackageTitle>
                          <p className="x-font-size x-text pb-xxl-5 mb-xxl-4 pb-xl-5 mb-xl-4 pb-lg-5 mb-lg-4 pb-md-5 mb-md-4 pb-sm-5 mb-sm-4">
                            Affordable travel to cheer the Cranes! Budget flights, low-cost stays, and public transport for cost-conscious fans.
                          </p>

                            <div className="mrc-button-spacer pb-5 pt-4">
                              <GradientButton className="mrcn-button" href="https://join.myxeno.com/?country=UG&goal=23">Get Started Today</GradientButton>
                            </div>
                          </div>
                        </PackageTextWrapper>


                    </div>
                    <div className="col-lg-6">
                      <PackageImageWrapper>
                        <img src={PackageOne} alt="Package 1"  className="mrc-desktop"/>
                      </PackageImageWrapper>

                    </div>
                  </div>
                </div>
              </GradientBackground>
              </ContentWrapper>

            </MoroccoExperienceSection>
          </Content>
        </div>

  );
};

export default ClientMoroccoExperiencePackage1
;
