import React from "react";
import styled from "styled-components";
import HeroBkg from "../../images/tulumbe/slice1.png";
import PhoneImg from "../../images/tulumbe/phone.png";
import PhoneImgMobile from "../../images/tulumbe/phone-mobile-2.png";
import ZellikImg from "../../images/tulumbe/zellij_pattern_1.png";
import "./blocks/image-card.css";
import "bootstrap/dist/css/bootstrap.min.css";

const TopRightElement = styled.div`
    position: absolute;
    top: -201px;
    left: -280px;
    width: 1190px;
    height: 1190px;
    background: url(${ZellikImg}) no-repeat center center/contain;
    z-index: 3;
    clip-path: inset(200px 0 0 0);
`;

const HeroWrapper = styled.div`
    background: url(${HeroBkg}) no-repeat center center/cover;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 3rem 1rem;

    @media (min-width: 768px) {
        padding: 4rem 2rem;
    }

    @media (min-width: 1200px) {
        padding: 6rem 4rem;
    }
`;

const Content = styled.div`
    .mrc-xcontainer {
        position: relative;
        z-index: 2;
        color: white;
        text-align: left;
        padding: 3rem;
        background-color: rgba(157, 211, 191, 0.15);
        margin: 2rem 0;
        border-radius: 30px;
        overflow: hidden;
    }

    @media (min-width: 768px) {
        padding: 4rem;
        
    }

    @media (min-width: 1200px) {
        padding: 5rem;
    }
`;



const PhoneContainer = styled.div`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 90%;
    height: 100%;
    overflow: hidden;

    @media (min-width: 1200px) {
        width: 80%;
    }

    @media (min-width: 1600px) {
        width: 70%;
    }
`;

const PhoneImage = styled.img`
    position: absolute;
    right: -15%;
    top: -39%;
    bottom: -10%;
    width: auto;
    height: 140%;
    object-fit: cover;

    @media (min-width: 1024px) {
        height: 114%;
        left: 25%;
        width: 100%;
        top: -88px;

        .lumba {
            width: 72%;
            max-width: 72%;
            margin-left: 200px;
        }
        
    }

    @media (min-width: 1200px) {
        height: 114%;
        left: 25%;
        width: 100%;
        top: -88px;
        .lumba {
            width: 72%;
            max-width: 72%;
            margin-left: 200px;
        }
    }

    @media (min-width: 1600px) {
        height: 114%;
        left: 25%;
        width: 100%;
        top: -59px;
        
    }
`;

const GradientText = styled.h2`
    font-weight: bold;
    background: linear-gradient(
            90deg,
            #f4eeba 0%,
            #ebedba 5%,
            #d4ecba 12%,
            #afeabb 21%,
            #7ce8bd 31%,
            #3be4bf 42%,
            #00e2c1 52%,
            #83eb9e 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    
`;

const StepContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 2rem;
`;

const StepNumber = styled.span`
    font-size: 24px;
    font-weight: 500;
    color: white;
    border: 1px solid ${(props) => props.bgColor || "#e9f5ff"};
    border-radius: 38px;
    width: 60px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
        font-size: 28px;
        width: 70px;
    }

    @media (min-width: 1200px) {
        font-size: 30px;
        width: 76px;
    }
`;

const StepContent = styled.div`
    flex: 1;
`;

const StepTitle = styled.strong`
    font-size: 1rem;
    font-weight: bold;
    color: #fff;

    @media (min-width: 768px) {
        font-size: 1.2rem;
    }

    @media (min-width: 1200px) {
        font-size: 1.25rem;
    }
`;

const StepDescription = styled.p`
    font-size: 1rem;
    color: #fff;
    margin: 0.5rem 0 0;

    @media (min-width: 768px) {
        font-size: 1.1rem;
    }

    @media (min-width: 1200px) {
        font-size: 1rem;
    }
`;

const HowItWorksContainer = () => {
  const steps = [
    { step: "01", title: "Select Your Plan:", text: "Choose from our three carefully curated experiences based on your budget and preferences.", bgColor: "#EBD666" },
    { step: "02", title: "Start AutoSaving:", text: "Automate savings with MTN MoMo or deposit manually via Airtel (Zero Fees) or Bank Transfer.", bgColor: "#F2F9D8" },
    { step: "03", title: "Track Progress:", text: "Track your progress through our user-friendly dashboard. Anytime, Anywhere.", bgColor: "#00E2C1" },
  ];

  return (
    <HeroWrapper
    className="hero-wrapper">
      <TopRightElement className="topLeftZellicPattern" />
      <div className="container-fluid text-white mx-lg-2 px-lg-2 mrc-desktop">
        <Content className="mrc-xcontainer">
          <div className="row align-items-center">
            <div className="col-md-6">
              <GradientText className="xn-h2">Start Your Journey</GradientText>
              <div className="mt-4 pb-lg-5">
                {steps.map(({ step, title, text, bgColor }) => (
                  <StepContainer key={step}>
                    <StepNumber bgColor={bgColor}>{step}</StepNumber>
                    <StepContent>
                      <StepTitle>{title}</StepTitle>
                      <StepDescription>{text}</StepDescription>
                    </StepContent>
                  </StepContainer>
                ))}
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center">
              <PhoneContainer>
                <PhoneImage src={PhoneImg} alt="Phone" />
              </PhoneContainer>
            </div>
          </div>
        </Content>
      </div>
      <div className="mrc-mobile">
        <div>

   <div>
     <div>
       <PhoneImage src={PhoneImgMobile} alt="Phone" className="phone-mobile"/>
     </div>
     <div>
       <div className="mrc-umtop">
         <Content className="mrc-xcontainer">
           <div className="row align-items-center">
             <div className="col-md-6">
               <GradientText className="xn-h2 text-center">Start Your Journey</GradientText>
               <div className="mt-4 pb-lg-5">
                 {steps.map(({ step, title, text, bgColor }) => (
                   <StepContainer key={step}>
                     <StepNumber className="xn-step" bgColor={bgColor}>{step}</StepNumber>
                     <StepContent className="stepper-margin">
                       <StepTitle className="strong">{title}</StepTitle>
                       <StepDescription className="p">{text}</StepDescription>
                     </StepContent>
                   </StepContainer>
                 ))}
               </div>
             </div>

           </div>
         </Content>
       </div>
     </div>
   </div>



        </div>

      </div>
    </HeroWrapper>
  );
};

export default HowItWorksContainer;
