import * as React from "react"
import styled from "styled-components"
import AppMenu from "../menu/app-container"
import LogoOffset from "./logo-offset"

const Box = styled.header`
  position: absolute;
  left: 0;
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TulumbeHeader = () => {
  return (
    <Box>
      <LogoOffset />
      <AppMenu />
    </Box>
  )
}
export default TulumbeHeader
