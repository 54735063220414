import styled from "styled-components";

// Styled Component for Gradient Button
const GradientButton = styled.a`
  background: linear-gradient(90deg, #4F5CC5 0%, #6F7DD6 31%, #9AF4E7 60%);
  border: none;
  color: #000; 
  font-size: 18px;
  font-weight: bold;
  padding: 12px 24px;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  outline: none;
  
  &:hover {
    opacity: 0.85;
  }
`;

export default GradientButton;
