import React from "react";
import styled from "styled-components";
import PackageThree from "../../images/tulumbe/package-3.png";
import PackageThreeMobile from "../../images/tulumbe/package-mobile-3.png";
import PackageNumberThree from "../../images/tulumbe/package-03.png";

import "bootstrap/dist/css/bootstrap.min.css";
import GradientBackground from "./gradient-background"
import GradientButton from "./blocks/gradient-button"

const Content = styled.div`
    margin-top: 4rem;
    margin-bottom: 4rem;
    .x-font-size {
        font-size: 22px;
    }
`;


const MoroccoExperienceSection = styled.div`
   display: flex; 
  flex-direction: column;
    align-items: center;
  text-align: center;
  color: white;
    position: relative;
    z-index: 2;
    padding-top: 50px;
    .btn-lg {
        --bs-btn-padding-y: 0.5rem;
        --bs-btn-padding-x: 1rem;
        --bs-btn-font-size: 1rem;
        --bs-btn-border-radius: var(--bs-border-radius-lg);
    }
    
`;


const PackageTitle = styled.h2`
  font-size: 75px;
  font-weight: bold;
  margin-bottom: 1rem;
    background: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: white;
    background-clip: text;
    color: white;
    width: 99.6%;
`;

const PackageImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    //margin-top: -50px;

 
    

    img {
        width: 100%;
        max-width: 100%;
    }


    margin-top: -100px;
    margin-right: -129px;
    margin-bottom: -55px;

 
`;

const ContentWrapper = styled.div`
    margin-top: 0px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; 
    width: 100%;
`;

const PackageTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    height: 100%; 
    text-align: left;
    .x-bottom-padding {
        padding-bottom: 8rem !important;
    }
`;

const ClientMoroccoExperiencePackage3 = () => {


  return (

        <div className="container-fluid text-white px-lg-5">
          <Content>
            <MoroccoExperienceSection>
              <ContentWrapper className="px-lg-5">
              <GradientBackground
                className="gradient-background"
                style={{ height: "auto", width: "100%" }}
                radius="30px">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-6">
                      <PackageTextWrapper className="mrc-padding">
                        <div className="mb-lg-5 pb-lg-5 mx-lg-5">
                          <img src={PackageNumberThree} className="pb-lg-4 mb-lg-4 mrc-desktop" alt="package three"/>
                          <div className="xrd-reduce-margin-1">
                          <img src={PackageThreeMobile} className="pb-lg-4 mb-lg-4 mrc-mobile" alt="package three"/>
                          </div>
                          <PackageTitle className="pb-lg-3 mb-lg-3 mrc-desktop">Luxury</PackageTitle>
                          <p className="x-font-size x-text pb-lg-5 mb-lg-4">
                           Enjoy a Premium AFCON experience with luxury hotels, private transport and fine dining for fans seeking top-tier comfort.
                          </p>

                          <div className="mrc-button-spacer pb-5 pt-4">
                            <GradientButton className="mrcn-button" href="https://join.myxeno.com/?country=UG&goal=23">Get Started Today</GradientButton>
                          </div>
                        </div>
                      </PackageTextWrapper>

                    </div>
                    <div className="col-lg-6">
                      <PackageImageWrapper className="left-negative-space">
                        <img src={PackageThree} alt="Package 3" className="mrc-desktop" />
                      </PackageImageWrapper>

                    </div>
                  </div>
                </div>
              </GradientBackground>
              </ContentWrapper>

            </MoroccoExperienceSection>
          </Content>
        </div>


  );
};

export default ClientMoroccoExperiencePackage3;
