import React from "react";
import styled from "styled-components";
import HappyFans from "../../images/tulumbe/happy-fans.png";
import HappyFansMobile from "../../images/tulumbe/happy-fans-mobile.png";

import "bootstrap/dist/css/bootstrap.min.css";

import MintBackground from "./mint-background";
import NormalButton from "./blocks/normal-button";



const Content = styled.div`
    margin-top: 4rem;
    margin-bottom: 4rem;
    .x-font-size {
        font-size: 25px;
    }
    .xn-marginTop50px {
        margin-top: 50px;
    }
    
    .xn-marginTopBottom {
        margin-bottom: -59px;
        margin-top: 40px;
    }
    

`;


const MoroccoExperienceSection = styled.div`
   display: flex; 
  flex-direction: column;
    align-items: center;
  text-align: center;
  color: #010F27;
    position: relative;
    z-index: 2;
    padding-top: 0px;
    .btn-lg {
        --bs-btn-padding-y: 0.5rem;
        --bs-btn-padding-x: 1rem;
        --bs-btn-font-size: 1rem;
        --bs-btn-border-radius: var(--bs-border-radius-lg);
    }
`;


const PackageTitle = styled.h2`
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 1rem;
  font-family: var(--xn-font-body);
    background-clip: text;
    color: #010F27;
    width: 99.6%;
`;

const PackageImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: -50px;
    margin-right: -30px;

    img {
        width: 100%;
        max-width: 100%;
    }
`;

const ContentWrapper = styled.div`
    margin-top: 45px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; 
    width: 100%;
  
`;

const PackageTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    height: 100%;
    padding-bottom: 70px;
    text-align: left;
    .x-bottom-padding {
        padding-bottom: 8rem !important;
    }
`;

const StillHaveQuestions = () => {


  return (
   <Content>
        <div className="container-fluid text-white px-lg-5 pt-sm-4">
            <MoroccoExperienceSection>
              <ContentWrapper className="px-lg-5">
              <MintBackground
                className="mint-background"
                style={{ height: "auto", width: "100%" }}
                radius="30px">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-7">
                      <PackageTextWrapper
                      className="xrd-reduce-margin">
                        <img src={HappyFansMobile} alt="Happy fans" className="mrc-mobile mxrc-top" />
                        <div className="mb-lg-5 pb-lg-5 mx-lg-5 pt-lg-3 mxrc-spacer-0">
                            <PackageTitle className="mxrc-title">Still Have Questions?</PackageTitle>
                            <p className="x-font-size-4 x-text mb-lg-2 mxrc-spacer-text">
                              Tulumbe Morocco, A Stress Free way to plan, save and invest for your trip to AFCON 2025.
                            </p>

                         <div className="mrc-desktop">
                           <div className="d-flex w-75 pt-lg-4 xn-marginTopBottom mrc-desktop">
                             <div>
                               <NormalButton href="https://support.myxeno.com/hc/en-us/sections/26199247050781-NEW-Tulumbe-Morocco">Learn More</NormalButton>
                             </div>
                           </div>
                         </div>


                          <div className="mrc-mobile">
                            <div className="d-flex justify-content-center pt-lg-4  mxrc-button-spacer mrc-mobile">
                              <div>
                                <NormalButton className="normal-button" href="https://support.myxeno.com/hc/en-us/sections/26199247050781-NEW-Tulumbe-Morocco">Learn More</NormalButton>
                              </div>
                            </div>
                          </div>



                        </div>
                      </PackageTextWrapper>

                    </div>
                    <div className="col-lg-5">
                      <PackageImageWrapper>
                        <img src={HappyFans} alt="Happy fans" className="mrc-desktop" />
                      </PackageImageWrapper>

                    </div>
                  </div>
                </div>
              </MintBackground>
              </ContentWrapper>

            </MoroccoExperienceSection>
        </div>
   </Content>

  );
};

export default StillHaveQuestions;
