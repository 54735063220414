import React from "react";
import styled from "styled-components";
import HeroBkg from "../../images/tulumbe/slice4.jpg";
import HeroBkgMobile from "../../images/tulumbe/mosque-background.png";
import ZellikImg from "../../images/tulumbe/zellij_round.png";

import "bootstrap/dist/css/bootstrap.min.css";


const DecorativeElement = styled.div`
  position: absolute;
    background: url(${ZellikImg}) no-repeat center center/contain;
        top: -185px;
        width: 690px;
        height: 690px;
        z-index: 10;
        pointer-events: none;
`;

const TopRightElement = styled(DecorativeElement) `
  right: -455px;
`;

const TopLeftElement = styled(DecorativeElement)`
  left: -455px;
`;

const HeroWrapper = styled.div`
    background: url(${HeroBkg}) no-repeat center center/cover;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    /* Adding black shade effect on edges */
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1; /* Ensure it overlays the background but not the content */
        pointer-events: none; /* Avoid blocking interactions */
        background: linear-gradient(
                to right,
                rgba(0, 0, 0, 0.7) 0%,
                rgba(0, 0, 0, 0) 20%,
                rgba(0, 0, 0, 0) 80%,
                rgba(0, 0, 0, 0.7) 100%
        ),
        linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0.7) 0%,
                rgba(0, 0, 0, 0) 30%
        );
    }
    @media (max-width: 576px) {
        background: url(${HeroBkgMobile}) no-repeat center !important;
        background-size: cover !important;
    }
`;

const Content = styled.div`
    margin-top: 9rem;
    margin-bottom: 4.5rem;
`;

const MoroccoExperienceSection = styled.div`
   display: flex; 
  flex-direction: column;
    align-items: center;
  text-align: center;
  color: white;
    position: relative;
    z-index: 2;
   height: 150vh;

`;

const MoroccoExperienceTitle = styled.h2`
  font-size: 50px;
    font-weight: bold;
    background: linear-gradient(
            90deg,
            #f4eeba 0%,
            #ebedba 5%,
            #d4ecba 12%,
            #afeabb 21%,
            #7ce8bd 31%,
            #3be4bf 42%,
            #00e2c1 52%,
            #83eb9e 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    width: 99.6%;
`;


const ClientMoroccoExperience = () => {


  return (
<HeroWrapper>
  <TopRightElement className="zellick-background"/>
  <TopLeftElement className="zellick-background"/>
        <div className="container text-white">
          <Content className="mrc-text-margin">
            <MoroccoExperienceSection>
              <MoroccoExperienceTitle className="xn-h2 w-75">How Would You Like To Experience Morocco?</MoroccoExperienceTitle>
            </MoroccoExperienceSection>
          </Content>
        </div>
</HeroWrapper>



  );
};

export default ClientMoroccoExperience;
