import styled from "styled-components";

// Styled Component for Normal Button
const NormalButton = styled.a`
  background: #010F27;
  border: none;
  color: #fff; 
  font-size: 18px;
  font-weight: bold;
  padding: 12px 24px;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  outline: none;
  
  &:hover {
    opacity: 0.85;
  }
`;

export default NormalButton;
