import React from "react"
import styled from "styled-components"
import XenoLogo from "../../images/xeno-logo-square_fill-mint.svg"
import { Link } from "gatsby"

const Box = styled.figure`
  height: 5rem;
  width: 5rem;
  flex-shrink: 0;
    
    .logo-spacing {
        margin-top: 50px;
        margin-left: 88px;
    }

  a {
    display: block;
    height: 100%;
    background-image: url("${XenoLogo}");
    background-size: contain;
    background-repeat: no-repeat;
  }
  span {
    opacity: 0;
    visibility: hidden;
  }

  @media (max-width: 1000px) {
    width: min(12vw, 5rem);
    height: min(12vw, 5rem);
  }
`

const LogoOffset = () => {
  return (
    <Box className="o-xeno-logo logo-spacing">
      <Link to="/">
        <span>XENO</span>
      </Link>
    </Box>
  )
}

export default LogoOffset;
