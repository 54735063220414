import React, { useState } from "react";
import IndividualImg from "../../../images/tulumbe/individual.png";
import GroupImg from "../../../images/tulumbe/group.png";
import CoupleImg from "../../../images/tulumbe/couple.png";
import "./image-card.css";

const ImageSelector = () => {
  const [selectedIndex, setSelectedIndex] = useState(null);

  const options = [
    { image: IndividualImg, text: "As an Individual", boldText: "Individual" },
    { image: GroupImg, text: "As a Group", boldText: "Group" },
    { image: CoupleImg, text: "As a Couple", boldText: "Couple" },
  ];

  return (
    <div className="image-selector-container">
      {options.map((option, index) => (
        <div
          key={index}
          className={`card-container ${selectedIndex === index ? "selected" : ""}`}
          onMouseEnter={() => setSelectedIndex(index)}
          onMouseLeave={() => setSelectedIndex(null)}
        >
          <div className="image-frame">
            <img src={option.image} alt={option.text} className="img-fluid" />
          </div>
         <a href="https://join.myxeno.com/?country=UG&goal=23"> <div className="btn btn-primary btn-sm rounded-5 px-lg-5 pt-lg-3 pb-lg-3 pt-md-1 pb-md-1 pt-sm-1 pb-sm-1 x-action xnbtn-lg text-overlay w-75">
            {option.text.replace(option.boldText, "")} <strong>{option.boldText}</strong>

         </div></a>
        </div>
      ))}
    </div>
  );
};

export default ImageSelector;
