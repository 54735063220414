import React from "react";
import styled from "styled-components";

import "bootstrap/dist/css/bootstrap.min.css";
import GradientBackground from "./gradient-background"
import FaqList from "./faq-list"

const Content = styled.div`
    margin-top: 0rem;
    margin-bottom: 4rem;
`;

const MoroccoExperienceSection = styled.div`
   display: flex; 
  flex-direction: column;
    align-items: center;
  text-align: center;
  color: white;
    position: relative;
    z-index: 2;
    .btn-lg {
        --bs-btn-padding-y: 0.5rem;
        --bs-btn-padding-x: 1rem;
        --bs-btn-font-size: 1rem;
        --bs-btn-border-radius: var(--bs-border-radius-lg);
    }
`;


const PackageTitle = styled.h2`
  font-size:56px;
  font-weight: bold;
  margin-bottom: 1rem;
    font-family: var(--xn-font-body);
    background: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: white;
    background-clip: text;
    color: white;
    width: 99.6%;
`;



const ContentWrapper = styled.div`
    margin-top: 0px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; 
    width: 100%;
`;

const PackageTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    text-align: left;
    .x-bottom-padding {
        padding-bottom: 8rem !important;
    }
`;

const FrequentlyAnsweredQuestions = () => {


  return (

        <div className="container-fluid text-white px-lg-5">
          <Content>
            <MoroccoExperienceSection>
              <ContentWrapper className="px-lg-5">
              <GradientBackground
                className="gradient-background"
                style={{ height: "auto", width: "100%" }}
                radius="30px">
                <div className="container-fluid faqpadding">
                  <div className="row">
                    <div className="col-lg-12">
                      <PackageTextWrapper>
                        <div className="mb-lg-3 mt-lg-5 mx-lg-5 mrcv-bottompadding">
                          <PackageTitle className="pb-lg-3 mb-lg-3 faqtitle">Your Questions Answered</PackageTitle>
                          <div className="x-font-size-3 x-text pb-lg-5 mb-lg-4">
                           <FaqList/>
                          </div>

                        </div>
                      </PackageTextWrapper>

                    </div>
                  </div>
                </div>
              </GradientBackground>
              </ContentWrapper>

            </MoroccoExperienceSection>
          </Content>
        </div>

  );
};

export default FrequentlyAnsweredQuestions;
