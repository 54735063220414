import React from "react";
import { Accordion } from "react-bootstrap";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import "./accordion.css";

const FAQItemWrapper = styled.div`
    position: relative;
    padding-bottom: 12px;
    padding-top: 12px;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(to right, #4f5cc5, #9af4e7);
    }


    .accordion-button {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
        font-size: 1rem;
        color: white; /* Ensure visibility */
        text-align: left;
        background-color: transparent !important; /* Makes it transparent */
        border: none;
        border-radius: 0;
        transition: var(--bs-accordion-transition);
    }

    .accordion-button:focus {
        box-shadow: none !important;
    }

    .accordion-item {
        background: rgba(255, 255, 255, 0.1) !important; /* Slight transparency */
        border: 1px solid rgba(255, 255, 255, 0.3) !important; /* Light border */
        backdrop-filter: blur(8px); /* Adds a subtle glass effect */
    }

    .accordion-body {
        background: transparent !important;
        color: white;
    }

    .x-font-size {
        font-size: 22px;
    }
`;

const QuestionText = styled.div`
    font-size: 25px;
    font-weight: 500;
    cursor: pointer;
    color: white;
    font-family: var(--xn-font-body);
`;

const FAQList = () => {
  const faqs = [
    { question: "How accurate are the  suggested amounts  in the plans?", answer: "Estimates factor in flights, accommodation, meals, visas, and activities. Actual costs may vary based on travel dates and preferences." },
    { question: "Are match tickets included in the budget?", answer: "Yes. The plans include estimated ticket costs, but you’ll need to purchase them separately." },
    { question: "Can I save with a Partner?", answer: "1. Yes. Select the ‘Tulumbe Morrocco’ Goal \n2. Choose whether you would like to invest ‘With a Partner’\n3. Once you’ve set up your goal – you can add your partner’s details\n4. Enter Your Partners details to send them an invitation to join your Joint Goal " },
    { question: "Can we save as a Group?", answer: "Yes. When you set up the goal, you have the option to save alongside your family or friends. Simply select the Group option and add members." }
  ];

  return (
    <Accordion flush>
      {faqs.map((faq, index) => (
        <Accordion.Item key={index} eventKey={index.toString()}>
          <FAQItemWrapper>
            <Accordion.Header>
              <QuestionText className="x-font-size-3">{faq.question}</QuestionText>
            </Accordion.Header>
            <Accordion.Body className="x-font-size">
              {faq.answer.split("\n").map((line, idx) => (
                <React.Fragment key={idx}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Accordion.Body>
          </FAQItemWrapper>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default FAQList;
