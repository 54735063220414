import * as React from "react"
import Toggle from "./toggle"
import Box from "./box"
import { items } from "../../data/menu"
import AppLoginBtn from "./appLoginBtn";
import AppMenuItem from "./app-menu-item";
import CountryFlag from "./country-flag"

const Ele = () => {
  const [state, setState] = React.useState(false)
  const handleClick = e => {
    setState(e)
  }
  return (
    <>
      <AppLoginBtn />
      <Toggle click={e => handleClick(e)} />
      <Box visible={state}>
        {items &&
          items.map((btn, index) => {
            return (
              <AppMenuItem
                submenuItems={btn.submenu}
                submenuClass={btn.submenuClass}
                key={index}
                to={btn.to}
                label={btn.label}
                cta={btn.cta}
              />
            )
          })}
        <CountryFlag countryCode="UG" />
      </Box>
    </>
  )
}
export default Ele
