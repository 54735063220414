import styled from "styled-components";

// Styled Component for Normal Button
const PrimaryButton = styled.a`
  background: #F2F9D8;
  border: none;
  color: #000000; 
  font-size: 18px;
  font-weight: bold;
    padding: 24px 50px;
    border-radius: 17rem;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  outline: none;
  
  &:hover {
    opacity: 0.85;
  }
`;

export default PrimaryButton;
